/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "./layout-new.css"
// import "./layout.css"

const Layout = ({ children, noPro, noProFooter }) => {
  return (
    <>
      <div className="header">
        <Link to="/">
          <StaticImage
            src="../images/logo-white.png"
            width={100}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="TripPlanR: Vos plannings de vacances sur mesure"
          />
        </Link>

        {!noPro && (
          <a
            className="button"
            href="https://tripplanr.co/"
            title="Découvrez notre solution"
            target="_blank"
            rel="noreferrer"
          >
            Pro du tourisme
          </a>
        )}
      </div>

      <div className="content">{children}</div>

      <div className="footer">
        {/*!noProFooter && <p>
          TripPlanR est un outil de planification de voyage dédié aux
          professionnels du tourisme. Nous avons adapté notre offre pour
          proposer des voyages sur mesure à la relance du tourisme. N'hésitez
          pas à nous contacter pour profiter de notre outils pour votre agence.
        </p>*/}

        <a href="https://tripplanr.co">
          <StaticImage
            src="../images/logo-white.png"
            width={200}
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="TripPlanR: Vos plannings de vacances sur mesure"
          />
        </a>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
